// Import Firebase modules using the new modular syntax
import { initializeApp } from "firebase/app";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; // Firestore importieren

// Deine Firebase Konfiguration
const firebaseConfig = {
  apiKey: "AIzaSyAueuZ7p5pKtr3ofVF848tmbdmiEW1OxVA",
  authDomain: "offtogether-9c7fa.firebaseapp.com",
  databaseURL:
    "https://offtogether-9c7fa-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "offtogether-9c7fa",
  storageBucket: "offtogether-9c7fa.appspot.com",
  messagingSenderId: "369641343917",
  appId: "1:369641343917:web:3ea8c840520fad48622f24",
  measurementId: "G-7ZWME5SPLQ",
};

// Firebase initialisieren
const app = initializeApp(firebaseConfig);
const auth = getAuth(app); // Auth-Instanz erstellen
const db = getFirestore(app); // Firestore-Instanz erstellen

export { auth, sendSignInLinkToEmail, db }; // `db` exportieren
