/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import "../../styles/06-pages/registerCompany.scss";

class RegisterCompany extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="lg-container">
        <div className="companyDetails">
          <p>Hallo</p>
        </div>
      </div>
    );
  }
}

export default RegisterCompany;
