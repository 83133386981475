import "./App.css";
import Header from "./views/components/header";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import SignUp from "./views/pages/SignUp";
import FinishSignUp from "./views/pages/FinishSignUp";
import RegisterCompany from "./views/pages/RegisterCompany";

function App() {
  return (
    <Router>
      <div className="renderPage">
        <Header />
        <div className="main">
          <Routes>
            <Route path="/" element={<SignUp />} />
            <Route path="/finishSignUp" element={<FinishSignUp />} />
            <Route path="/registerCompany" element={<RegisterCompany />} />
            <Route path="/dashboard" element={<RegisterCompany />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
