import React, { useState } from "react";
import "../../styles/05-forms/emailForm.scss";
import "../../styles/06-pages/signUp.scss";
import { auth, sendSignInLinkToEmail } from "../../firebase-config";

const Label = ({ htmlFor, children }) => (
  <label htmlFor={htmlFor} className="label">
    {children}
  </label>
);

const Input = ({ type, id, name, placeholder, error, ...props }) => (
  <div className="input-container">
    <input
      type={type}
      id={id}
      name={name}
      placeholder={placeholder}
      className={`input ${error ? "input-error" : ""}`}
      {...props}
    />
    {error && <p className="error-message">{error}</p>}
  </div>
);

const Button = ({ onClick, children, disabled }) => (
  <button onClick={onClick} className="button" disabled={disabled}>
    {children}
  </button>
);

const EmailForm = () => {
  const [email, setEmail] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validierung der E-Mail-Adresse
    if (!isValidEmail(email)) {
      setEmailError("Bitte geben Sie eine gültige E-Mail-Adresse ein.");
      return;
    }

    // Firebase-Login mit E-Mail-Link
    try {
      await sendSignInLinkToEmail(auth, email, {
        url: "https://business.offtogether.de/#/finishSignUp", // URL, zu der der Benutzer nach dem Klick auf den Link weitergeleitet wird
        handleCodeInApp: true, // Der Link wird in der App selbst verarbeitet
      });

      // Speichern des E-Mail-Links im LocalStorage, um den Link zu validieren
      window.localStorage.setItem("emailForSignIn", email);

      setSuccessMessage(
        "Wir haben Ihnen einen Link zur Anmeldung gesendet. Bitte überprüfen Sie Ihre E-Mails."
      );
    } catch (error) {
      console.error(error);
      alert("Es gab ein Problem beim Senden des Anmelde-Links.");
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError("");
    setSuccessMessage("");
  };

  const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  return (
    <form onSubmit={handleSubmit} className="email-form">
      <Label htmlFor="email">Email-Adresse</Label>
      <Input
        type="email"
        id="email"
        name="email"
        placeholder="Ihre Email hier eingeben"
        value={email}
        onChange={handleEmailChange}
        error={emailError}
      />

      {/* Checkbox für AGB-Zustimmung */}
      <div className="terms-container">
        <input
          type="checkbox"
          id="terms"
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
        />
        <label htmlFor="terms" className="termsText">
          Ich habe die <a href="#">Allgemeinen Geschäftsbedingungen</a> und die{" "}
          <a href="#">Datenschutzerklärung</a> gelesen und akzeptiere diese. Ich
          willige ein, dass meine Daten gemäß der Datenschutzerklärung
          verarbeitet werden.
        </label>
      </div>

      {/* Button ist deaktiviert, wenn Checkbox nicht ausgewählt ist */}
      <Button disabled={!isChecked}>Weiter mit Email</Button>

      {successMessage && <p className="success-message">{successMessage}</p>}
    </form>
  );
};

export default EmailForm;
