import React, { Component } from "react";
import "../../styles/03-components/header.scss";
import { Link } from "react-router-dom";

class Header extends Component {
  render() {
    return (
      <div className="ot-header">
        <div className="ot-header__container">
          <Link to="/">
            <div className="ot-header__logo">
              <h1>
                Off<span>Together</span>
              </h1>
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

export default Header;
