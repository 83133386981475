/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import EmailForm from "../forms/EmailForm";
import "../../styles/06-pages/signUp.scss";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false, // Checkbox State
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleCheckboxChange = () => {
    this.setState((prevState) => ({
      isChecked: !prevState.isChecked,
    }));
  };

  render() {
    return (
      <div className="sm-container">
        <div className="signUp">
          <h2>Anmelden oder Account erstellen</h2>
          <h4 className="signUp__description">
            Nutzen Sie unsere OT-Services – sicher und einfach. Geben Sie Ihre
            E-Mail-Adresse ein, um sich anzumelden.
          </h4>
          <EmailForm />
        </div>
      </div>
    );
  }
}

export default SignUp;
