import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Verwende useNavigate anstelle von useHistory
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth"; // Richtig importieren
import { db } from "../../firebase-config"; // Firestore importieren
import { doc, setDoc, getDoc } from "firebase/firestore";

const FinishSignUp = () => {
  const navigate = useNavigate(); // Verwende useNavigate, um das Routing durchzuführen
  const auth = getAuth(); // Hole das Auth-Objekt mit getAuth

  useEffect(() => {
    const email = window.localStorage.getItem("emailForSignIn");
    if (!email) {
      navigate("/"); // Wenn keine E-Mail gefunden wird, zurück zur Startseite
    }

    const url = window.location.href;

    if (isSignInWithEmailLink(auth, url)) {
      // Verwende die richtige Methode
      signInWithEmailLink(auth, email, url)
        .then(async (result) => {
          const user = result.user;
          const userRef = doc(db, "companies", user.uid);

          // Überprüfen, ob der Nutzer bereits existiert
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            navigate("/dashboard"); // Falls Nutzer existiert, direkt zum Dashboard
          } else {
            // Falls der Nutzer noch nicht existiert, neuen Eintrag in Firestore erstellen
            await setDoc(userRef, {
              uid: user.uid,
              email: user.email,
              createdAt: new Date(),
            });

            navigate("/registerCompany"); // Falls neu, weiter zur Welcome-Seite
          }

          window.localStorage.removeItem("emailForSignIn"); // Aufräumen
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [navigate, auth]); // `auth` muss auch in den Abhängigkeits-Array aufgenommen werden

  return (
    <div>
      <h2>Verifiziere deine E-Mail-Adresse</h2>
      <p>Bitte warten, während wir deine Anmeldung abschließen...</p>
    </div>
  );
};

export default FinishSignUp;
